@tailwind base;
@tailwind components;
@tailwind utilities;


/*====================================================================
                   custom fonts configuration 
======================================================================*/
/* bold-italic */
@font-face {
  font-family: "fEuclidLimadiBoldItalic";
  font-weight: 700;
  src: local("poppins"), url(./app/fonts/Euclid\ Circular\ B\ Bold\ Italic.ttf) format("truetype");
  font-style: italic;
}

/* bold */
@font-face {
  font-family: "fEuclidLimadiBold";
  font-weight: 700;
  src: local("poppins"), url(./app/fonts/Euclid\ Circular\ B\ Bold.ttf) format("truetype");
  font-style: normal;
}

/* light */
@font-face {
  font-family: "fEuclidLimadiLight";
  font-weight: 300;
  src: local("poppins"), url(./app/fonts/Euclid\ Circular\ B\ Light.ttf) format("truetype");
  font-style: normal;
}

/* light-italic */
@font-face {
  font-family: "fEuclidLimadiLightItalic";
  font-weight: 300;
  src: local("poppins"), url(./app/fonts/Euclid\ Circular\ B\ Light\ Italic.ttf) format("truetype");
  font-style: italic;
}

/* medium */
@font-face {
  font-family: "fEuclidLimadiMedium";
  font-weight: 500;
  src: local("poppins"), url(./app/fonts/Euclid\ Circular\ B\ Medium.ttf) format("truetype");
  font-style: normal;
}

/* medium-italic */
@font-face {
  font-family: "fEuclidLimadiMediumItalic";
  font-weight: 500;
  src: local("poppins"), url(./app/fonts/Euclid\ Circular\ B\ Medium\ Italic.ttf) format("truetype");
  font-style: italic;
}

/* regular */
@font-face {
  font-family: "fEuclidLimadiRegular";
  font-weight: 400;
  src: local("poppins"), url(./app/fonts/Euclid\ Circular\ B\ Regular.ttf) format("truetype");
  font-style: normal;
}

/* regular-italic */
@font-face {
  font-family: "fEuclidLimadiRegularItalic";
  font-weight: 400;
  src: local("poppins"), url(./app/fonts/Euclid\ Circular\ B\ Italic.ttf) format("truetype");
  font-style: italic;
}

/* semibold */
@font-face {
  font-family: "fEuclidLimadiSemibold";
  font-weight: 600;
  src: local("poppins"), url(./app/fonts/Euclid\ Circular\ B\ SemiBold.ttf) format("truetype");
  font-style: normal;
}

/* semibold-italic */
@font-face {
  font-family: "fEuclidLimadiSemiboldItalic";
  font-weight: 600;
  src: local("poppins"), url(./app/fonts/Euclid\ Circular\ B\ SemiBold\ Italic.ttf) format("truetype");
  font-style: italic;
}

/* Add these styles to your CSS file */
.opening-animation {
  transition: transform 0.3s ease-in-out;
}

.closing-animation {
  transition: transform 0.3s ease-in-out;
}

input {
  font-family: "fEuclidLimadiRegular" !important;
}

.apply-all * {
  font-size: revert;
  font-family: revert;
  font-weight: revert;
  line-height: revert;
  list-style: revert;
  text-decoration: revert;
  margin: revert;
  padding: revert;
  color: revert;
}

.ql-editor {
  max-height: 320px;
  min-height: 320px;
  height: 320px;
}


/* Styles for large screens (width 1920px and above) */
@media (min-width: 1881px) {
  .largeScreenContainer {
    width: 1880px;
    /* Set a fixed width for the container */
    margin: 0 auto;
    /* Center the container */
  }

  .table-view-product {
    max-width: 350px;
    min-width: 350px;
  }
}

@media (min-width: 10px) and (max-width: 1681px) {
  .license_card {
    justify-content: space-between;
  }

  .license_card_width {
    max-width: 230px;
    min-width: 230px;
  }

  .image_padding {
    margin-top: 16px;
    margin-bottom: 35px;
  }

  .vehicle-available-shift {
    max-width: 29.3px;
    min-width: 29.3px;
  }

  .driver-available-shift {
    max-width: 40.5px;
    min-width: 40.5px;
  }

  .date-available-shift {
    max-width: 65.5px;
    min-width: 65.5px;
  }

  .table-view-product {
    max-width: 230px;
    min-width: 230px;
  }

}

@media (min-width: 1681px) {
  .license_card {
    justify-content: space-evenly;
  }

  .license_card_width {
    max-width: 256px;
    min-width: 256px;
  }

  .image_padding {
    margin-top: 16px;
    margin-bottom: 36px;
  }

  .vehicle-available-shift {
    max-width: 25.5px;
    min-width: 25.5px;
  }

  .driver-available-shift {
    max-width: 37px;
    min-width: 37px;
  }

  .date-available-shift {
    max-width: 61px;
    min-width: 61px;
  }

  .table-view-product {
    max-width: 360px;
    min-width: 360px;
  }

}

/* Styles for medium screens (width up to 1420px) */
@media (max-width: 1440px) {
  .main {
    width: 1440px;
    /* Set a fixed width for the content */
    overflow-x: auto;
    scrollbar-width: none;
    /* Add a horizontal scrollbar when the content overflows the container's width */
  }

  .table-view-product {
    max-width: 230px;
    min-width: 230px;
  }
}

.custom-scrollbars {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
}


/* ====================================================================
            custom styles for Limadi WEB
====================================================================*/

.limadi-font-bold-italic {
  font-family: 'fEuclidLimadiBoldItalic';
}

.limadi-font-bold {
  font-family: 'fEuclidLimadiBold';
}

.limadi-font-light {
  font-family: 'fEuclidLimadiLight';
}

.limadi-font-light-italic {
  font-family: 'fEuclidLimadiLightItalic';
}

.limadi-font-medium {
  font-family: 'fEuclidLimadiMedium';
}

.limadi-font-medium-italic {
  font-family: 'fEuclidLimadiMediumItalic';
}

.limadi-font-regular {
  font-family: 'fEuclidLimadiRegular';
}

.limadi-font-regular-italic {
  font-family: 'fEuclidLimadiRegularItalic';
}

.limadi-font-semibold {
  font-family: 'fEuclidLimadiSemibold';
}

.limadi-font-semibold-italic {
  font-family: 'fEuclidLimadiSemiboldItalic';
}

.scrollbar-width-none {
  scrollbar-width: none;
}

.custom-text {
  hyphens: auto;
  word-wrap: break-word;
  text-align: justify;
  white-space: pre-line;
  overflow-wrap: break-word;

  /* Added break-all property */
}

.custom-text::after {
  content: "-";
  visibility: hidden;
  display: inline-block;
  width: 0;
}








/* ====================================================================
                            Sidebar fixes
====================================================================*/


/* custom style fixes */
.z-index-120 {
  z-index: 120;
}

.z-index-130 {
  z-index: 130;
}

.sidebar-expand-arrow-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 0px 4px rgba(0, 0, 0, 0.25);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}


/* ====================================================================
                            Slider fixes
====================================================================*/
.box {
  position: absolute;
  cursor: move;
  /* color: black; */
  max-width: 170px;
  border-radius: 6px;
  padding: 6px;
  margin: auto;
  user-select: none;
}

/* Remove Arrows/Spinners */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 55px;
  background: #ffffff;
  outline: none;
  border: 2px solid #285D43;
  border-radius: 999999px;
  padding-left: 5px;
  padding-right: 5px;

  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 44px;
  height: 44px;
  background-image: url('./images/svg/slider-button-image.svg');
  border-radius: 999999px;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 44px;
  height: 44px;
  background-image: url('./images/svg/slider-button-image.svg');
  border-radius: 999999px;
  cursor: pointer;
}

.border-rs {
  position: relative;
}

.border-rs::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  /* Adjust border width here */
  height: 50%;
  /* Adjust border height here */
  background: black;
  /* Adjust border color here */
}

/* ====================================================================
                            common styles
====================================================================*/
/* table border fix */

.table-border-outer {
  border-collapse: collapse;
  border-radius: 10px;
  border-style: hidden;
  overflow: hidden;
  margin: -1px;
  /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #D1D0E2;

  /* this draws the table border  */
}

/* box shadow all around */
.full-shadow-around {
  -webkit-box-shadow: 0px 0px 34px -2px rgba(184,184,184,1);
-moz-box-shadow: 0px 0px 34px -2px rgba(184,184,184,1);
box-shadow: 0px 0px 34px -2px rgba(184,184,184,1);

}

.abc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.settingBar {
  font-weight: 800 !important;
  font-size: 14px;
  font-family: sans-serif;
}

@layer components {
  .limadi-regular {
    /* for 400 or regular fonts */
    @apply font-normal limadi-font-regular;
  }

  .limadi-medium {
    /* for 500 or medium fonts */
    @apply font-medium limadi-font-medium;
  }

  .limadi-semibold {
    /* for 600 or semibold fonts */
    @apply font-semibold limadi-font-semibold;
  }

  .limadi-bold {
    /* for 700 or bold fonts */
    @apply font-bold limadi-font-bold;
  }


  .title {
    @apply text-fs28 text-cMainBlack limadi-semibold
  }

  .sub-title {
    @apply text-fs24 text-cMainBlack limadi-regular
  }

  .cp {
    @apply cursor-pointer
  }

  .table-title {
    @apply text-fs14 text-cMainBlack limadi-bold
  }

  .item-title {
    @apply text-fs14 font-fw600 leading-[25.2px]
  }

  .item-title-normal {
    @apply text-fs14 font-fw400 leading-[25.2px]
  }

  .content-title {
    @apply text-fs18 font-fw500 leading-[24px]
  }

  .content {
    @apply text-fs14 font-fw400
  }

  .table-info {
    @apply text-fs12 text-cMainBlack font-normal limadi-regular px-3 py-4
  }

  .table-hover:hover {
    @apply bg-cPrimary100 rounded-b-[10px]
  }

  .info {
    @apply text-[14px] text-cTextGray limadi-semibold text-center
  }

  .normal-text {
    @apply text-[12px] font-normal text-cGray500 leading-[19.2px]
  }

  .title-text {
    @apply text-[20px] font-medium text-cGray800 leading-[24px]
  }


  .summaryBorder {
    @apply border-t-[1px] border-cGray200 pt-2
  }

  .checkBoxLabel {
    @apply font-normal text-fs14 text-cGray800
  }

}